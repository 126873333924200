import React, { useEffect, useRef, useState } from "react";
import ContactSales from "../../components/ContactSales";
import Header from "../../components/Header";
import mobileImage from "../../assets/images/hr-on-go.webp";
import realtimePayrollImg from "../../assets/images/realtime-payroll.webp";
import selectingRolesImg from "../../assets/images/selecting-roles.webp";
import globalTeamImg from "../../assets/images/global-team.webp";
import neverSwitchImg from "../../assets/images/never-switch.webp";
import intPeoImg from "../../assets/images/IntPeoLogo.png";
import intPayImg from "../../assets/images/IntPayLogo.png";
import Hero from "../../components/Hero";

const Home = ({ navigate, path }) => {
  const homeRef = useRef(null);
  const aboutRef = useRef(null);
  const recruitingRef = useRef(null);
  const complianceRef = useRef(null);
  const performanceRef = useRef(null);
  const payrollRef = useRef(null);
  const [activeSection, setActiveSection] = useState(null);

  const handleIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setActiveSection(entry.target.id);
      }
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.5,
    });

    observer.observe(homeRef.current);
    observer.observe(aboutRef.current);
    observer.observe(recruitingRef.current);
    observer.observe(complianceRef.current);
    observer.observe(performanceRef.current);
    observer.observe(payrollRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    const newPath = path.substring(1);
    const section = document.getElementById(newPath);
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [path]);

  return (
    <div className="app-container">
      <Header
        activeSection={activeSection}
        homeRef={homeRef}
        aboutRef={aboutRef}
        recruitingRef={recruitingRef}
        complianceRef={complianceRef}
        performanceRef={performanceRef}
        payrollRef={payrollRef}
        navigate={navigate}
      />
      <div ref={homeRef} id="home">
        <Hero />
      </div>
      <section className="bg-[#f9fcf8] px-5 lg:pr-10 py-8">
        <div className="flex items-center gap-12 lg:gap-20 flex-col md:flex-row">
          <div className="w-full lg:w-1/2">
            <img
              loading="lazy"
              src={neverSwitchImg}
              alt="mobile"
              className="w-full h-auto object-cover 2xl:w-1/2"
            />
          </div>
          <div className="md:w-[600px]">
            <h5 className="text-[#08192C] text-4xl lg:text-5xl mb-7 lg:font-normal">
              Never Switch Platforms Again.
            </h5>
            <p className="italic mb-5">
              Single integrated Management Platform.
            </p>
            <div className="text-base lg:text-2xl">
              Manage and empower your global workforce with ease. Regardless of
              the size of your organization, Ae'lkimi enables you to set up all
              your employee information and access it on demand, and on any
              device from a single source of truth. Recover your valuable time,
              so you can focus on what matters most - your organization and your
              employee's career journey, with workflow automation processes.
            </div>
          </div>
        </div>
      </section>
      <section ref={aboutRef} id="about" className="bg-white px-5 lg:px-10 py-12">
        <div className="flex items-center justify-between lg:justify-center gap-12 lg:gap-20 flex-col md:flex-row">
          <div className="md:w-[600px]">
            <h5 className="text-[#08192C] text-4xl lg:text-5xl mb-7 lg:font-normal">
              Compliance for a global team - with a local feel.
            </h5>
            <div className="text-base lg:text-2xl">
              Frustrated with the ever-changing landscape of regulation and compliance? Are you struggling with:
              <ul className="px-8">
                <li>Employment Laws</li>
                <li>Tax Regulations</li>
                <li>Payroll Laws</li>
                <li>Multilingual contracts</li>
                <li>International benefits administration</li>
              </ul>
              <div className="mt-4" />
              Well, that's our problem and not yours! You manage the team, while our multi-country compliance repository ensures that you, your employees and the organization is legally compliant and up-to-date with all the laws.
            </div>
          </div>
          <div className="w-full lg:w-1/2">
            <img
              loading="lazy"
              src={globalTeamImg}
              alt="mobile"
              className="object-cover object-center lg:ml-auto 2xl:w-1/2"
            />
          </div>
        </div>
      </section>
      <section
        ref={recruitingRef}
        id="management"
        className="bg-[#f9fcf8] px-5 lg:pr-10 lg:-ml-5 py-8"
      >
        <div className="flex items-center gap-12 lg:gap-20 flex-col md:flex-row">
          <div className="w-full lg:w-1/2">
            <img
              loading="lazy"
              src={selectingRolesImg}
              alt="mobile"
              className="object-cover object-center mx-auto 2xl:w-1/2"
            />
          </div>
          <div className="md:w-[600px]">
            <h5 className="text-[#08192C] text-4xl lg:text-5xl mb-7 lg:font-normal">
              Manage your growing team, no matter where they are.
            </h5>
            <div className="text-base lg:text-2xl">
              Eliminate manual data entry and separate scheduling tools. Track time, create and share schedules, manage attendance, and sync hours with payroll effortlessly. 
              A streamlined approach to managing time and attendance confidently. With a single, easy-to-use tool;
              <ul className="mt-6 px-8">
                <li>- You can track hours, create and manage schedules faster (reducing cost).</li>
                <li>- A simple PTO to manage in real-time—all with automated syncing to payroll.</li>
                <li>- Designed for accuracy and efficiency, to relieve the administrative burden, and to simplify compliance and minimize errors.</li>
                <li>- Capture all the data with clear transparency in one report.</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section
        ref={complianceRef}
        id="compliance"
        className="bg-white px-5 lg:pl-10 py-12"
      >
        <div className="flex items-center justify-between lg:justify-center gap-12 lg:gap-20 flex-col md:flex-row">
          <div className="md:w-[600px]">
            <h5 className="text-[#08192C] text-4xl lg:text-5xl mb-7 lg:font-normal">
              Real time payroll processing - don't want until the last minute
            </h5>
            <div className="text-base lg:text-2xl">
              Stressing, panicking and feeling overwhelmed? Why are you going
              through this every single month? Why is your payroll not running
              24/7 in the background and working for you? Ae'lkimi enables you
              to give trust and control to your employees, where they can upload
              the monthly changes and pre-run their own payroll, while our
              platform updates in real-time all the changes - giving you
              cashflow visibility both locally and internationally. With AI
              support pre-empting your payroll, you can now save hours of
              administration, reduce human errors and focus on more critical
              tasks.
            </div>
          </div>
          <div className="w-full lg:w-1/2 lg:-mr-12">
            <img
              loading="lazy"
              src={realtimePayrollImg}
              alt="mobile"
              className="object-cover object-center mx-auto 2xl:w-1/2"
            />
          </div>
        </div>
      </section>
      <section
        ref={performanceRef}
        id="performance"
        className="bg-[#f9fcf8] px-5 lg:px-10 py-8"
      >
        <div className="flex items-center justify-between lg:justify-center gap-12 lg:gap-20 flex-col md:flex-row">
          <div className="w-full lg:w-1/2">
            <img
              loading="lazy"
              src={mobileImage}
              alt="mobile"
              className="object-cover object-center mx-auto 2xl:w-1/2"
            />
          </div>
          <div className="md:w-[600px]">
            <h5 className="text-[#08192C] text-4xl lg:text-5xl mb-7 lg:font-normal">
              HR On the Go
            </h5>
            <p className="italic mb-5">
              Empowering your workforce on the go, anytime, anywhere ...
            </p>
            <div className="text-base lg:text-2xl">
              Today's workforce needs access to their data 24/7,anytime,
              anywhere, at the click of a button. With Ae'lkimi's mobile app,
              your employees will never feel too far from accessing support.
              Whether it is administration, expense claims, reimbursements,
              payslip downloads, payment advances or requests, Ae'lkimi's mobile
              app makes addressing SLAs quick and painless.
            </div>
          </div>
        </div>
      </section>
      <section
        id="clients"
        className="bg-white px-5 lg:px-[60px] py-12"
      >
        <div className="text-center text-[#08192C] text-[44px]">Our Clients</div>
        <div className="flex items-center justify-between mt-[60px] gap-12 lg:gap-5 flex-col md:flex-row">
          <div className="md:w-[630px] bg-[#F9FCF8] p-[20px]">
            <h5 className="text-[#08192C] text-4xl lg:text-[32px] lg:leading-[40px] mb-4 lg:font-medium">
            IntPay Ltd
            </h5>
            <div className="text-base lg:text-2xl">
            IntPay Ltd is a UK based, long standing provider of International Payroll Strategy and Implementation Services with knowledge and experience of many of the large global payroll service providers. Particularly ADP including Globalview, Streamline and Celergo. Kevin Parker Founder and CEO has in the past worked for a number of the global service providers, and has 20 years of knowledge of this solution landscape and highly competitive market. Experience includes Strategy Consulting and Implementations, some with listed companies as well as small medium businesses. His work in the payroll supply chain has led to a vast range of country contacts that can reach every corner of the globe and can offer solutions in every legal jurisdiction in the world.
            </div>
            <div className="mt-[40px] md:mt-[80px] flex items-center flex-row gap-x-4">
            <img
              loading="lazy"
              src={intPayImg}
              alt="intpay"
              className="w-[135px] h-[40px] md:w-[200px] md:h-[60px]"
            />
              <span className="underline text-[#08192C] font-medium text-[16px] lg:text-[18px] cursor-pointer" onClick={
                () => window.open("https://www.linkedin.com/in/kevin-parker-963717/", "_blank")
              }>
              Kevin Parker | LinkedIn
              </span>
            </div>
          </div>
          <div className="md:w-[630px] bg-[#F9FCF8] p-[20px]">
            <h5 className="text-[#08192C] text-4xl lg:text-[32px] lg:leading-[40px] mb-4 lg:font-medium">
            IntPEO Ltd
            </h5>
            <div className="text-base lg:text-2xl">
            IntPEO Ltd is the new name in the Payroll and EoR market but the team are certainly not new faces. Launched in 2023 by Kevin Parker and building on 20 years of experience in the market place, IntPEO offers Payroll and Employer of Record Services around the globe. Whether you need services for 1 employee or many 000’s, from the American Seaboard to the South Pacific Seas, IntPEO can help. We do not believe in a ‘one size fits all’ approach and work with clients to advise and uncover the true requirement and ideal solution, rather than try to fit square pegs in round holes. We are driven by the needs of the customer and the employee experience which is at the heart of our operation. We tailor our solutions to individual requirements.
            </div>
            <div className="mt-[40px] md:mt-[80px] flex items-center flex-row gap-x-4">
            <img
              loading="lazy"
              src={intPeoImg}
              alt="intpeo"
              className="w-[135px] h-[40px] md:w-[200px] md:h-[60px]"
            />
              <span className="underline text-[#08192C] font-medium text-[18px] cursor-pointer" onClick={
                () => window.open("https://intpeo.com/", "_blank")
              }>
              IntPeo Website
              </span>
            </div>
          </div>
        </div>
      </section>
      <div className="w-full border-t-[1px] border-[#ECEFF3]" />
      <ContactSales ref={payrollRef} id="payroll" />
    </div>
  );
};

export default Home;
