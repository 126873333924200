import React from "react";
import Header from "../../components/Header";

const TermsOfService = ({ navigate }) => {
  const openUrl = (url) => {
    window.open(url, "_blank")
  }
  const openEmail = () => {
    window.location = 'mailto:leong.cheah@aelkimi.com'
  }
  return (
    <>
      <Header navigate={navigate} />
      <div className="max-w-[960px] px-4 lg:px-0 mx-auto mb-24 pt-32">
        <h1 className="text-black text-3xl lg:text-5xl font-semibold pb-12 lg:pb-16 text-center">
        Terms Of Use & Legal Information
        </h1>
        <hr className="" />
        <div className="mt-16 flex flex-col !gap-y-8">
          <p>Powered by <span className="underline cursor-pointer" onClick={() => openUrl('https://new-legal.com/')}>New Legal</span></p>
          <p><strong>Update Date:</strong> 19 January 2025</p>
          <p>
          Thanks for visiting Aelkimi Inc – FZCO’ s Website. These are our Terms of Use. They’re the T&Cs that apply to your use of the Website <span className="underline cursor-pointer" onClick={() => {openUrl('https://aelkimi.com/')}}>https://aelkimi.com/</span> By using the Website, you accept these terms and confirm that you are the age of majority
          </p>
          <p>IF YOU DON’T AGREE TO THESE TERMS, YOU MUST DISCONTINUE USE OF THE WEBSITE</p>
          <p>Consumer? These terms will not limit any non-waivable warranties or consumer protection rights that you may be entitled to under applicable law in the country you reside. </p>
          <p>We respect your personal data. See our Privacy Notice here: <span className="underline cursor-pointer" onClick={() => openUrl('https://aelkimi.com/privacy-policy')}>https://aelkimi.com/privacy-policy</span></p>
          <p>(1)   <strong>We are:</strong> Aelkimi Inc – FZCO, a company registered in the Dubai Integrated Economic Zones (DIEZ), specifically within the Dubai Silicon Oasis (DSO) free zone (“Business”, “we”, “our”, and “us”). </p>
          <p>Contact details: Leong Cheah – <span className="underline cursor-pointer" onClick={openEmail}>leong.cheah@aelkimi.com</span>
          <br />Website: <span className="underline cursor-pointer" onClick={() => openUrl('https://aelkimi.com/')}>https://aelkimi.com/</span> (“Website”)</p>
          <p>(2)  <strong>You are</strong> the person or the person on behalf of an entity (‘you’, ‘your’ and ‘yours’) using the Website.</p>
          <p>(3)  These <strong>Terms of Use (‘these terms’)</strong> are a legally binding agreement between you and us and take effect any time you use the Website. We grant you a limited revocable license to access the Website (or parts of it) and content on it made available to you from time to time. We may amend these terms at any time and updates are effective immediately upon publishing them to the Website. </p>
          <p>(4)  <strong>If you represent an entity</strong>, you must ensure you can accept these terms for them. </p>
          <p>(5)  <strong>We may update and change the Website or any content</strong> (namely content, materials, digital products, and resources in any form) from time to time without notice.</p>
          <p>(6) <strong>We cannot guarantee that the Website or any content on it will always be available</strong>. We may suspend, withdraw, or restrict the availability of all or any part of the Website or its content without notice.</p>
          <p>(7)  <strong>Intellectual property</strong>. We or our licensors are the owner or the licensee of all intellectual property rights in our Website and any content we may allow you to view or download, and they are protected by copyright laws and treaties around the world. All such rights are reserved. You may use content your for personal, educational or internal business purposes only, unless/until we tell you to stop doing so, and you aren’t permitted to licence it or offer it for sale.</p>
          <p><strong>Brand assets</strong>. Our logo, brand name, business name, taglines, product and service names and any brand assets, are our proprietary assets, including trade marks and service marks (whether registered or unregistered). Any use of them without our prior written consent is strictly prohibited.</p>
          <p>(8)  <strong>Personal use, and prohibition on distribution</strong>. The Website and any content on it are for your personal, non-commercial use only, and nothing contained on the Website is intended for use or distribution in any way or to any country or jurisdiction where doing so would be contrary to any laws or regulations or subject us to any registration or compliance requirements.</p>
          <p>(9)  <strong>Prohibited uses</strong>. (i) You may not do anything that in any way that breaches any applicable local, national or international law or regulation; or send, knowingly receive, upload, download, use or re-use any material which does not comply with these terms. (ii) Do not attack or seek to gain unauthorised access to the Website, systems, computers, databases or servers – doing so is illegal.  (iii) You shall not conduct, facilitate, authorise or permit any text or data mining or web scraping in relation to the Website or content. This includes using (or permitting, authorising or attempting the use of): any “robot”, “bot”, “spider”, “scraper”, artificial intelligence or other automated device, program, tool, algorithm, code, process or methodology to access, obtain, copy, monitor or republish any portion of the Website or any data, content, information or services accessed via the same; or any automated analytical technique aimed at analysing text and data in digital form to generate information which includes but is not limited to patterns, trends and correlations. The provisions in this clause should be treated as an express reservation of our rights in this regard, including for the purposes of Article 4(3) of Digital Copyright Directive ((EU) 2019/790). This clause shall not apply insofar as (but only to the extent that) we are unable to exclude or limit text or data mining or web scraping activity by contract under the laws which are applicable to us.</p>
          <p>(10)  <strong>We are not responsible for viruses and you must not introduce them</strong>. We do not guarantee that the Website or content will be secure or free from bugs or viruses. Use protective software. </p>
          <p>(11)  <strong>Interactive services</strong>. We may from time to time provide interactive services via the Website, including, without limitation streaming and live virtual events, group video calls, video-sharing facilities, chat, bulletin boards, forums, or groups or any other interactive service, and we expressly exclude our liability for any loss or damage arising from the use of any interactive service by a user in contravention of these terms or any other agreed terms, whether the service is moderated or not.</p>
          <p>(12)  <strong>Breach of these terms</strong>. When we consider that a breach of these terms has occurred, we may take such action as we deem appropriate, and we reserve the right to restrict your access to the Website immediately without notice in the event of such a breach.</p>
          <strong>DISCLAIMERS, LIMITATION OF LIABILITY AND INDEMNITIES</strong>
          <p>
          (13)(1) <strong>We don’t exclude or limit our liability to you where it would be unlawful to do so</strong>. References to liability in this clause 14 include every kind of liability arising under or in connection with these terms, for example liability in contract, tort (including negligence), misrepresentation, restitution, breach of statutory duty, or otherwise.
<br />(13)(2) <strong>Cap on liability</strong>.  Except in the case of those exceptions, our total aggregate liability to you arising under or in connection with these terms will be limited to £10.
<br />(13)(3)  <strong>Exclusions</strong>. To the fullest extent permitted by law, and excluding those exceptions, the following types of loss arising out of or in connection with these terms are wholly excluded by us: (i) loss of profits; (ii) loss of sales or business; (iii) loss of agreements or contracts; (iv) loss of use or corruption of software, data or information; (v) loss of or damage to reputation or goodwill; (vi) indirect or consequential loss; (vii) loss of anticipated savings and (viii) loss arising as a result of our complying with our legal and regulatory duties. 
<br />(13)(4) <strong>Indemnity</strong>. (a) You shall indemnify us and our affiliates and licensors against all liabilities, costs, expenses, damages and losses (including but not limited to any direct, indirect or consequential losses, loss of profit, loss of reputation and all interest, penalties and legal costs (calculated on a full indemnity basis) and all other reasonable professional costs and expenses) suffered or incurred or paid by us arising out of or in connection with: any claim brought against us (or our affiliates and licensors) for infringement of a third party's rights (including any intellectual property rights) arising out of, or in connection with, your breach of these terms or violation of applicable law, notably your misuse of our Website or content
          </p>
          <p>(14) <strong>We will not be liable to you for any loss or damage</strong>, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with: use of, or inability to use, the Website; or use of or reliance on any content displayed on the Website. </p>
          <p>(15) <strong>The Website is provided “as is” and “as available”</strong>. To the fullest extent permitted by law, we and our affiliates and licensors exclude all conditions, warranties and representations (express, implied, statutory or otherwise) with respect to the Website and content on it, and disclaim all warranties including but not limited to warranties of fitness for purpose and satisfactory quality. We do not warrant that the Website will be uninterrupted, accurate or free of errors</p>
          <p>(16)  <strong>If you are a consumer, please note that we only provide the Website to you for domestic and private use</strong>. You agree not to use the Website for any commercial or business purposes, and we have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity. We recommend that you always try to keep your browser up to date, as newer versions provide higher levels of security</p>
          <p>(17) <strong>The Website content is provided to you for informational purposes only</strong>. While we endeavour to keep the Website content up-to-date, and while it has been obtained from sources believed to be reliable, we don’t make any representation, warranty (express, implied or otherwise) or guarantee as to the completeness, accuracy, timeliness or suitability of any part of the content or that it is free from error or omission. It does not constitute legal, financial or tax advice, or any other form of professional advice, and is not a substitute for such advice. Any reliance you place on such information is strictly at your own risk.</p>
          <p>(18) <strong>General restrictions</strong>. You shall not: (a) except as may be allowed by any applicable law which is incapable of exclusion by agreement between the parties and except to the extent expressly permitted under the agreement: (i) attempt to copy, modify, duplicate, create derivative works from, frame, mirror, republish, download, display, transmit, or distribute all or any portion of the Website in any form or media or by any means; or (ii) attempt to de-compile, reverse compile, disassemble, reverse engineer or otherwise reduce to human-perceivable form all or any part of the Website; (b) access all or any part of the Website in order to build a product or service which competes with ours; (c) use the Website to provide services to third parties, unless agreed; (d) licence, sell, rent, lease, transfer, assign, distribute, display, disclose, or otherwise commercially exploit the Website; (e) make the Website available to any third party except the authorised users; or (f) attempt to obtain, or assist third parties in obtaining, access to the Website.</p>
          <p>(19)  <strong>Third party products and services presented on the Website or in content</strong>. We do not make any representation, warranty (express or implied) or guarantee as to the suitability of any third party products or services for you needs, or that the third party products or services are in any way a complete range of possible options for your needs. </p>
          <p>(20)  <strong>Links and third parties</strong>. Any content that contains hyperlinks to or mention of any third party provider or products or services is not an endorsement by us of those persons, their content, products or services or views, or the accuracy or suitability thereof. We are not responsible for their materials, content, information or otherwise.</p>
          <p>GENERAL</p>
          <p>(21) <strong>Waiver</strong>.  If a party chooses not to enforce a right or use a remedy, it must clearly state this in writing, which does not mean they give up any rights or remedies. Not immediately using a right or remedy does not mean it is waived. Using a right or remedy partially or once does not stop its future use or effect</p>
          <p>(22) <strong>Severance</strong>.  If any provision or part-provision of these terms is or becomes invalid, illegal or unenforceable, it shall be deemed modified to the minimum extent necessary to make it valid, legal and enforceable. If that is not possible, the relevant provision or part-provision shall be deemed deleted. Any such modification or deletion shall not affect the validity and enforceability of the rest of these terms.</p>
          <p>(23)  <strong>Communication and notices</strong>.  Any communication between you and us relating to these terms must be in writing, using the latest contact details provided. Notices are considered received when signed for in person, two business days (in England) after mailing, or on email transmission.</p>
          <p>(24)  <strong>Survival</strong>.  Every provision of these terms that expressly or by implication is intended to, shall come into or continue in force on or after its termination or expiry.</p>
          <p>(25) <strong>Governing law and jurisdiction</strong>.  These terms and any related dispute or claim will be governed by and construed according to the laws of England and Wales. Both parties irrevocably agree that only the courts of England and Wales have the authority to settle any dispute or claim.</p>
        </div>
      </div>
    </>
  );
};

export default TermsOfService;
