import React from "react";
import Header from "../../components/Header";

const PrivacyPolicy = ({ navigate }) => {
  const openUrl = (url) => {
    window.open(url, "_blank")
  }
  const openEmail = () => {
    window.location = 'mailto:leong.cheah@aelkimi.com'
  }
  return (
    <>
      <Header navigate={navigate} />
      <div className="max-w-[960px] px-4 lg:px-0 mx-auto mb-24 pt-32">
        <h1 className="text-black text-3xl lg:text-5xl font-semibold pb-12 lg:pb-16 text-center">
        Privacy Notice
        </h1>
        <hr className="" />
        <div className="mt-16 flex flex-col !gap-y-8">
          <p>Powered by <span className="underline cursor-pointer" onClick={() => openUrl('https://new-legal.com/')}>New Legal</span></p>
          <p><strong>Update Date:</strong> 19 January 2025</p>
          <p>This is our privacy notice, which contains our cookie policy. Please read the following carefully to understand our views and practices regarding your personal data.</p>
          <strong>Legal Information</strong>
          <p>
          <strong>We are:</strong> Aelkimi INC – FZCO, a company registered in the Dubai Integrated Economic Zones (DIEZ), specifically within the Dubai Silicon Oasis (DSO) free zone (<strong>“Business”</strong>, <strong>“we”</strong>, <strong>“our”</strong>, and <strong>us”</strong>“).
<br /><strong>Contact details:</strong> Leong Cheah – <span className="underline cursor-pointer" onClick={openEmail}>leong.cheah@aelkimi.com</span>
<br /><strong>Website:</strong> <span className="underline cursor-pointer" onClick={() => openUrl('https://aelkimi.com/')}>https://aelkimi.com/</span> (<strong>“Website”</strong>)
<br /><strong>Data Protection Officer:</strong> Leong Cheah – <span className="underline cursor-pointer" onClick={openEmail}>leong.cheah@aelkimi.com</span>
          </p>
          <strong>Cookies - N/A</strong>
          <div>
            <strong>Introduction</strong>
            <p>
            We take the privacy and security of your personal data seriously and ensure that all personal data is processed transparently, fairly, and lawfully. Our data protection practices are aligned with the highest standards, ensuring we uphold the rights of individuals.
<br />As part of our commitment to privacy and data protection, we adhere to the relevant data protection laws, including the <strong>UK General Data Protection Regulation (UK GDPR)</strong> and the <strong>UAE Federal Personal Data Protection Law (PDPL)</strong>.
<br />We respect your privacy and are committed to protecting your personal data and being transparent about the personal data we collect and use.
            </p>
          </div>
          <p>This privacy notice applies to you and describes why and how we collect and use personal data and provides information about your rights. It applies to any personal data we collect or obtain about you or that you provide to us through the Website, or any platforms, websites or apps, including social media sites,  when you purchase a product or service, contribute, speak or participate in any of our webinars,  events, sessions or workshops (in person, virtual, live or recorded) (<strong>“Sessions”</strong>), or communicate or interact with us in any other way.</p>
          <p>The Website is not intended for children under the age of 18 and we do not knowingly collect data relating to them. </p>
          <p>It is important that you read this privacy notice together with any other privacy/data protection notice or clauses or fair processing notice (<strong>other privacy notices</strong>) we may provide on specific occasions when we are collecting or processing personal data about you so that you are fully aware of how and why we are using your data. This privacy notice supplements other notices and privacy policies and is not intended to override them, except where otherwise stated in those other privacy policies.</p>
          <p>Please also read the Glossary which explains the meanings of some of the terms used in this privacy notice.</p>
          <div>
            <strong>UAE</strong>
            <p><strong>In addition to practices set out in this notice, the following apply to our activities in the UAE (as applicable, and subject to any applicable territorial laws):</strong>
<br />Explicit Consent for Sensitive Personal Data: We obtain <strong>explicit consent</strong> before processing sensitive personal data, such as health, genetic, biometric, and children's data.
<br /><strong>Lawful Basis for Processing</strong>: We ensure that personal data processing is conducted on the lawful bases outlined under the <strong>UAE PDPL</strong>, such as:</p>
          </div>
          <p>Necessity of processing for the performance of a contract.
<br />Compliance with legal obligations.
<br />Protection of vital interests.
            <br />Legitimate interests, while respecting individual rights.</p>
          <p><strong>Data Subject Rights</strong>: We respect the rights of individuals under the UAE PDPL, including the right to:</p>
          <p>– <strong>Access</strong> personal data.
<br /><strong>– Rectify</strong> or <strong>delete</strong> personal data.
            <br /><strong>– Object</strong> to processing and request <strong>restriction</strong> of processing.</p>
          <p>UAE residents can exercise these rights by contacting us directly, and we will address these requests as per the requirements of the <strong>PDPL.</strong></p>
          <p><strong>Cross-Border Data Transfers:</strong> When transferring personal data outside the UAE, we ensure compliance with the <strong>UAE PDPL</strong> by implementing appropriate safeguards. This includes:</p>
          <p>– Ensuring adequate data protection levels in recipient countries, where applicable.
            – Utilizing <strong>Standard Contractual Clauses (SCCs)</strong> or other legally approved mechanisms, as required by the <strong>UAE Data Protection Office.</strong></p>
          <p><strong>Data Breach Notification</strong>: In the event of a personal data breach, we are committed to notifying the <strong>UAE Data Office</strong> within 72 hours of identifying a breach that may impact the rights of individuals within the UAE. We will also take appropriate actions to mitigate the effects of such breaches and inform affected individuals where necessary.
<br />Data Security Measures: We implement appropriate technical and organizational measures to safeguard personal data against unauthorized access, loss, or disclosure. These measures include data encryption, access controls, and regular audits to ensure the ongoing security of personal data in compliance with the UAE PDPL..
            <br /><strong>(1) Important information and who we are</strong></p>
          <p><strong>Controller</strong>
            <br />The Business described in the Legal Information is the data controller and responsible for your personal data (collectively referred to as <strong>“Business”, "we", "us"</strong> or <strong>"our"</strong> in this privacy notice). </p>
          <p>We have appointed a data privacy manager (and/or Data Protection Officer, if applicable) who is responsible for overseeing questions in relation to this privacy notice. If you have any questions about this privacy notice, including any requests to exercise your legal rights, please contact the data privacy manager using the Contact Details in the Legal Information. </p>
          <p><strong>Contact Details</strong>
            <br />If you have any questions about this privacy notice or our privacy practices, please contact our data privacy manager (or Data Protection Officer) using the Contact Details in the Legal Information.</p>
          <p><strong>Complaints</strong>
<br />If you are a resident of the UAE, you may contact the UAE Data Protection Office for issues relating to the processing of your personal data under Federal Decree-Law No. 45 of 2021 (PDPL). You can find more information and contact details here: https://u.ae/en/about-the-uae/digital-uae/data/data-protection-laws
If you are located in the DIFC, the independent regulator for data protection is the Office of the Commissioner of Data Protection. You can find more information and contact details here: https://www.difc.ae/business/operating/data-protection/.
If you are located in the ADGM, the relevant authority is the Office of Data Protection (ODP). Contact details are available here: https://www.adgm.com/operating-in-adgm/office-of-data-protection.
If you are a resident of the UK, you have the right to make a complaint at any time, including where you believe we are unlawful processing your data,  to the Information Commissioner's Office (ICO), the UK regulator for data protection issues (www.ico.org.uk). 
            If you are a resident in the EEA and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection authority. You can find their contact details here: <span className="underline cursor-pointer" onClick={() => openUrl('https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.html')}>https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.html</span> .</p>
          <p>If you are a resident in Switzerland, the contact details for the data protection authorities are available here: <span className="underline cursor-pointer" onClick={() => openUrl('https://www.edoeb.admin.ch/edoeb/en/home.html.')}>https://www.edoeb.admin.ch/edoeb/en/home.html.</span></p>
          We would, however, appreciate the chance to deal with your concerns before you approach the ICO or your local  data protection authority so please contact us in the first instance.
          <p><strong>Changes to the privacy notice and your duty to inform us of changes</strong>
<br />We keep our privacy notice under regular review. We reserve the right to change it at any time without notice and we encourage you to read it periodically to ensure that you are at all times fully aware of it. Any changes are effective immediately upon posting to our Website or written notice to you.
            This version was last updated on Update Date first mentioned above. It is important that the personal data we hold about you is accurate and current. Please keep us informed if your personal data changes during your relationship with us.</p>
          <p><strong>Third-party links</strong>
            <br />This Website may include links to third-party websites, plug-ins, applications and/or other materials which are not provided by us. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. These are not under our control and we are not responsible for their privacy notices/policies. Please note that they and any services that may be accessible through them should have their own privacy notices/policies and that we do not accept any responsibility or liability for the activities, privacy policies/notices or levels of privacy compliance of any third-party websites, plug-ins, applications and/or other materials operated by any third party or for any content which is not under our control. We recommend  that you check their privacy policies/notices before you submit any personal data to any of them.</p>
          <strong>(2) The data we collect about you</strong>
          <p>Personal data, or personal information, means any information about an individual from which that person can be identified. It does not include data where the identity has been removed (anonymous data). We may collect, use, store and transfer different kinds of personal data about you which we have grouped together as follows:</p>
          <p><strong>– Identity Data</strong> includes first name, last name, any previous names, username or similar identifier, marital status, title, place of birth, date of birth and gender; job title, profession and photograph; and information contained in your curriculum vitae or your profile on social media.
<br /><strong>– Contact Data</strong> includes billing address, delivery address, previous addresses, email address and telephone numbers, business address, business email addresses and telephone numbers.
<br /><strong>– Financial Data</strong> includes bank account and payment card details.
<br /><strong>– Transaction Data</strong> includes details about payments to and from you and other details of products and services you have purchased from us.
<br /><strong>– Technical Data</strong> includes information about how you use our website, products and services, and device-specific data such as device’s IP address (captured and stored in an anonymized format), device screen resolution, device type (unique device identifiers), operating system and browser type, geographic location (country only), and user interactions (mouse events (movements, location and clicks) and keypresses and log data (referring URL and domain, pages visited, geographic location (country only)), preferred language used to display the webpage and date and time when website pages were accessed).
<br /><strong>– Profile Data</strong> includes your username and password, purchases or orders made by you, your interests, preferences, feedback and survey responses.  
<br /><strong>– Usage Data</strong> includes information about how you use our Website, products and services. 
            <br /><strong>– Marketing and Communications Data</strong> includes your preferences in receiving marketing from us and our third parties and your communication preferences.</p>
          <p>We also collect, use and share Aggregated Data such as statistical or demographic data for any purpose. <strong>Aggregated Data</strong> could be derived from your personal data but is <strong>not</strong> considered personal data in law as this data will not directly or indirectly reveal your identity. For example, we may aggregate your Usage Data to calculate the percentage of users accessing a specific Website feature. 
            <br />However, if we combine or connect Aggregated Data with your personal data so that it can directly or indirectly identify you, we treat the combined data as personal data which will be used in accordance with this privacy notice.</p>
          <p>In some cases it may be necessary to collect <strong>Special Categories of Personal Data</strong> about you (this includes details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your health, and genetic and biometric data). </p>
          <p>This information will only be processed with your explicit consent or as otherwise permitted by data protection law</p>
          <p>We do not collect any information or personal data about your criminal convictions and offences.</p>
          <p><strong>If you fail to provide personal data</strong>
            <br />Where we need to collect personal data by law, or under the terms of a contract we have with you, and you fail to provide that data when requested, we may not be able to perform any contract we have or are trying to enter into with you (for example, to provide you with products or services). In this case, we may have to cancel a product or service you have with us but we will notify you if this is the case at the time.</p>
          <strong>(3) How is your personal data collected?</strong>
          <p>We use different methods to collect data from and about you including through:
            <br />(a) <strong>Direct interactions.</strong> You may give us your Identity, Contact and Financial Data by filling in forms or by corresponding with us by post, phone, email, via an account dashboard or otherwise. This includes personal data you provide when you:</p>
          <p>– apply for our products or services;
<br />– purchase and use our products and services;
<br />– register as a customer or user;
<br />– create an account on the Website; 
<br />– join any subscription or membership;
<br />– join any of our groups on social media or in person;
<br />– subscribe to our service or publications, including newsletters; 
<br />– request marketing to be sent to you;
<br />– enter a competition, promotion or survey; 
<br />– engage with us and content on the Website or via any platform, website or app, including any social media site; 
<br />– contribute, speak or participate in any of our Sessions, such as webinars, events, sessions or workshops (in person, virtual, live or recorded);
<br />– feature in our publications or attend our events or request us to feature in or attend yours, such as podcasts or other audio-visual or written publications and related marketing materials;
<br />– take part in our market research;
<br />– give us feedback or contact us; 
            <br />– interact with us in any other way.</p>
          <p>(b) <strong>Automated technologies or interactions.</strong> As you interact with the Website, we will automatically collect Technical Data about your equipment, browsing actions and patterns. We collect this personal data by using cookies, server logs and other similar technologies. We may also receive Technical Data about you if you visit other Websites employing our cookies. Please see our cookie notice below for further details.</p>
          <p>(c) <strong>Third parties or publicly available sources.</strong> We will receive personal data about you from various third parties and public sources as set out below: </p>
          <p>(i) Technical Data is collected from the following parties:
<br />– analytics providers based inside and outside the UK; 
<br />– advertising networks based inside and outside the UK; and
<br />– search information providers based inside and outside the UK.</p>
          <p>(ii) Contact, Financial and Transaction Data from providers of technical, payment and delivery services inside and outside the UK.</p>
          <p>(iii) Identity, Contact and Financial Data from credit checking and compliance screening service providers based inside and outside the UK.</p>
          <p>(iv) Identity and Contact Data from publicly available sources such as Companies House based inside the UK or similar outside the UK.</p>
          <strong>(4) How we use your personal data</strong>
          <p>We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances:
<br />– Where we need to perform the contract we are about to enter into or have entered into with you.
<br />– Where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests.
<br />– Where we need to comply with a legal obligation.
            <br />– Where we have obtained your active agreement to use your personal data for a specified purpose.</p>
          <p>Please read the Glossary to find out more about the types of lawful basis that we will rely on to process your personal data.
            Generally, we do not rely on consent as a legal basis for processing your personal data although, where legally required, we will get your consent before sending third party direct marketing communications to you via phone, email or text message. You have the right to withdraw consent to marketing at any time by contacting us</p>
          <strong>(5) Purposes for which we may use your personal data</strong>
          <p>We have set out below, a description of all the ways we plan to use your personal data, and which of the legal bases we rely on to do so. We have also identified what our legitimate interests are where appropriate.
            Note that we may process your personal data for more than one lawful ground depending on the specific purpose for which we are using your data. Please contact us if you need details about the specific legal ground we are relying on to process your personal data where more than one ground has been set out below.</p>
          <p><strong>Purpose/Activity:</strong> To register you as a new customer.
<br /><strong>Type of data:</strong> (a) Identity, (b) Contact.
            <br /><strong>Lawful basis for processing, including basis of legitimate interest:</strong> Performance of a contract with you.</p>
            <p><strong>Purpose/Activity:</strong> To process and deliver your order for products or services, manage payments, fees, and charges, and collect and recover money owed to us.
<br /><strong>Type of data:</strong> (a) Identity, (b) Contact, (c) Financial, (d) Transaction, (e) Marketing and Communications.
            <br /><strong>Lawful basis for processing, including basis of legitimate interest:</strong> (a) Performance of a contract with you, (b) Necessary for our legitimate interests (to recover debts due to us).</p>
            <p><strong>Purpose/Activity:</strong> To manage our relationship with you, including notifying you about changes to our terms and conditions, policies, or privacy notice, and requesting reviews or surveys.
<br /><strong>Type of data:</strong>  (a) Identity, (b) Contact, (c) Profile, (d) Marketing and Communications.
            <br /><strong>Lawful basis for processing, including basis of legitimate interest:</strong> (a) Performance of a contract with you, (b) Necessary to comply with a legal obligation, (c) Necessary for our legitimate interests (to keep our records updated and study customer usage patterns).</p>
            <p><strong>Purpose/Activity:</strong> To enable your participation in prize draws, competitions, or surveys.
<br /><strong>Type of data:</strong> (a) Identity, (b) Contact, (c) Profile, (d) Usage, (e) Marketing and Communications.
            <br /><strong>Lawful basis for processing, including basis of legitimate interest:</strong> (a) Performance of a contract with you, (b) Necessary for our legitimate interests (to study customer usage patterns, develop products/services, and grow our business).</p>
            <p><strong>Purpose/Activity:</strong> To administer and protect our business and website, including troubleshooting, data analysis, testing, system maintenance, support, reporting, and data hosting.
<br /><strong>Type of data:</strong> (a) Identity, (b) Contact, (c) Technical.
            <br /><strong>Lawful basis for processing, including basis of legitimate interest:</strong> (a) Necessary for our legitimate interests (running our business, providing administration and IT services, network security, preventing fraud, and business reorganization or restructuring), (b) Necessary to comply with a legal obligation.</p>
            <p><strong>Purpose/Activity:</strong> To deliver relevant website content and advertisements to you, and measure the effectiveness of the advertising served
<br /><strong>Type of data:</strong> (a) Identity, (b) Contact, (c) Profile, (d) Usage, (e) Marketing and Communications, (f) Technical.
            <br /><strong>Lawful basis for processing, including basis of legitimate interest:</strong> (a) Necessary for our legitimate interests (studying customer usage patterns, developing products/services, growing our business, and informing marketing strategy) (b) consent (for targeted advertising).</p>
            <p><strong>Purpose/Activity:</strong> To use data analytics to improve the Website, products/services, marketing, customer relationships, and experiences.
<br /><strong>Type of data:</strong> (a) Technical, (b) Usage
            <br /><strong>Lawful basis for processing, including basis of legitimate interest:</strong> Necessary for our legitimate interests (defining customer types, keeping the website updated, developing our business, and informing marketing strategy).</p>
            <p><strong>Purpose/Activity:</strong> To make suggestions and recommendations about goods or services that may be of interest to you.
<br /><strong>Type of data:</strong> (a) Identity, (b) Contact, (c) Technical, (d) Usage, (e) Profile, (f) Marketing and Communications.
            <br /><strong>Lawful basis for processing, including basis of legitimate interest:</strong> (a) Necessary for our legitimate interests (developing products/services and growing our business), (b) consent.</p>
            <p><strong>Purpose/Activity:</strong> To be involved in our Sessions, and any activities which arise during the course of or after thee these or in their preparation or development. 
<br /><strong>Type of data:</strong> (a) Identity, (b) Contact, (c) Financial Data (if you are paid).
            <br /><strong>Lawful basis for processing, including basis of legitimate interest:</strong> (a) Necessary for our legitimate interests (growing our business), (b) consent (c) Performance of a contract.</p>
            <p><strong>Purpose/Activity:</strong> To recruit the best and most appropriate speakers, contributors, and participants and crew for our Sessions.
<br /><strong>Type of data:</strong> (a) Identity, (b) Contact
            <br /><strong>Lawful basis for processing, including basis of legitimate interest:</strong> (a) Necessary for our legitimate interests (developing products/services and growing our business), (b) consent (c) Performance of a contract.</p>
            <p><strong>Purpose/Activity:</strong> To film, record  and photograph you for the purpose of your inclusion in our Sessions, and their distribution and exploitation and their advertising and marketing. 
<br /><strong>Type of data:</strong> (a) Identity, (b) Contact
            <br /><strong>Lawful basis for processing, including basis of legitimate interest:</strong> (a) Necessary for our legitimate interests (growing our business), (b) consent (c) Performance of a contract.</p>
          <strong>(6) Marketing </strong>
          <p>We strive to provide you with choices regarding certain personal data uses, particularly around marketing and advertising. You can change your preferences by following the opt-out links on any marketing message sent to you, or by contacting us at any time using the Contact Details or, if we offer this functionality, by logging into the Website and checking or unchecking relevant boxes.</p>
          <p>Please read the Glossary to find out more about how the law applies to digital marketing to businesses and consumers</p>
          <p><strong>Marketing and promotional offers from us if you are a business </strong>
            <br />We may use your Identity, Contact, Technical, Usage and Profile Data to form a view on what we think you may want or need, or what may be of interest to you. This is how we decide which products, services and offers may be relevant for you (we call this marketing). You will receive marketing communications from us if you have requested information from us or purchased products or services from us and you have not opted out of receiving that marketing.</p>
          <p><strong>Marketing and promotional offers from us if you are a consumer </strong>
            <br />Where you have indicated your consent to receiving marketing information including offers or promotions about our products or services by ticking the relevant box(es), we will use your Contact Data for these purposes.  You will receive marketing communications from us if you have requested information from us or purchased products or services from us and you have not opted out of receiving that marketing.  </p>
          <p><strong>Third-party marketing: businesses and consumers </strong>
            <br />We will obtain your express opt-in consent before we share your personal data with any third party for marketing purposes. </p>
          <p><strong>Opting out</strong>
            <br />You can ask us or third parties to stop sending you marketing messages at any time by following the opt-out links on any marketing message sent to you, by contacting us at any time using the Contact Details or, if we offer this functionality, by logging into the Website and checking or unchecking relevant boxes to adjust your marketing preferences. Where you opt out of receiving these marketing messages, this will not apply to personal data provided to us as a result of a product/service purchase, warranty registration, product/service experience or other transactions or service-related communications that are essential for administrative or customer service purposes.</p>
          <strong>(7) Cookie policy</strong>
          <p>We use the cookies set out in the Legal Information above, or as detailed in the cookie consent tool/settings on the Website.</p>
          <p>Our Website uses cookies to distinguish you from other users of the Website. This helps us to provide you with a good experience when you browse the Website and also allows us to improve the Website. A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer if you agree. Cookies contain information that is transferred to your computer's hard drive.</p>
          <p>Our Cookies</p>
          <p>We may use the following cookies:</p>
          <p><strong>– Strictly necessary cookies.</strong> These are cookies that are required for the operation of the Website. They include, for example, cookies that enable you to log into secure areas of the Website, use a shopping cart or make use of e-billing services.
<br /><strong>– Analytical or performance cookies.</strong> These allow us to recognise and count the number of visitors and to see how visitors move around the Website when they are using it. This helps us to improve the way our Website works, for example, by ensuring that users are finding what they are looking for easily.
<br /><strong>– Functionality cookies.</strong> These are used to recognise you when you return to the  Website. This enables us to personalise our content for you, greet you by name and remember your preferences (for example, your choice of language or region).
            <br /><strong>– Targeting cookies.</strong> These cookies record your visit to the Website, the pages you have visited and the links you have followed. We will use this information to make the Website and the advertising displayed on it more relevant to your interests. We may also share this information with third parties for this purpose.</p>
          <p><span className="underline">Third party cookies</span>
            <br />Please note that third parties may also use cookies, over which we have no control. These named third parties may include, for example, advertising networks and providers of external services like web traffic analysis services. These third party cookies are likely to be analytical cookies, performance cookies or targeting cookies.</p>
          <p><span className="underline">Cookie controls</span>
            <br />To change your cookie settings, deactivate or block our cookies or the use of third party advertising cookies, please see below. You can choose which analytical, functionality and targeting cookies you accept by adjusting your settings via our cookie settings banner/widget on your screen. You can do this by adjusting the sliders to opt in or out of the cookie categories. To save your changes, scroll to the bottom and click on the save button. If you cannot see the save button, you may be using a blocker to prevent the setting of cookies. </p>
          <p><span className="underline">Browser cookie controls</span>
            <br />You can block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block all cookies (including essential cookies) you may not be able to access all or parts of our Website.</p>
          <p>Information about how to configure your browser to block cookies can be found at:
            <br />- <span className="underline cursor-pointer" onClick={() => openUrl('https://support.google.com/chrome/answer/95647')}>Google Chrome</span>
            <br />- <span className="underline cursor-pointer" onClick={() => openUrl('https://support.microsoft.com/en-us/search?query=enable%20cookies%20in%20edge')}>Microsoft Edge</span>
            <br />- <span className="underline cursor-pointer" onClick={() => openUrl('https://support.microsoft.com/en-us/windows/manage-cookies-in-microsoft-edge-view-allow-block-delete-and-use-168dab11-0753-043d-7c16-ede5947fc64d')}>Internet Explorer</span>
            <br />- <span className="underline cursor-pointer" onClick={() => openUrl('https://support.mozilla.org/en-US/kb/block-websites-storing-cookies-site-data-firefox')}>Firefox</span>
            <br />- <span className="underline cursor-pointer" onClick={() => openUrl('https://support.apple.com/en-gb/guide/safari/ibrw850f6c51/mac')}>Safari</span>
            <br />- <span className="underline cursor-pointer" onClick={() => openUrl('https://support.apple.com/en-us/105082')}>Safari Mobile</span>
            <br />- <span className="underline cursor-pointer" onClick={() => openUrl('https://blogs.opera.com/news/2015/08/how-to-manage-cookies-in-opera/')}>Opera</span>
          </p>
          <p>If you prefer to not have data reported by Google Analytics, you can install the Google Analytics Opt-Out Browser Add-On by following the instructions <span className="underline cursor-pointer" onClick={() => openUrl('https://support.google.com/analytics/answer/181881?hl=en')}>here.</span>
            <br />You can find out how to opt out of Google’s use of tracking technologies via the Google advertising opt-out page <span className="underline cursor-pointer" onClick={() => openUrl('https://support.google.com/My-Ad-Center-Help/answer/12155764?hl=en-GB&visit_id=638763344729336051-1535545318&rd=1')}>here.</span></p>
          <p>Advertising
<br />To learn more about adverts which are displayed to you whilst you are online, please consult the applicable site’s privacy and cookie notice on which you see our ads.
            <br />The advertising companies we work with generally use cookies and similar technologies as part of their services. To learn more about how advertisers generally use cookies and the choices they offer, you can review the following resources:</p>
          <p>- <span className="underline cursor-pointer" onClick={() => openUrl('https://optout.aboutads.info/?c=2&lang=EN')}>Digital Advertising Alliance</span>
            <br />- <span className="underline cursor-pointer" onClick={() => openUrl('https://youronlinechoices.eu/')}>European Interactive Digital Advertising Alliance</span></p>
          <p>Expiry of cookies</p>
          <p>The cookies will expire as set out in the Legal Information or cookie consent tool/settings on the Website.</p>
          <p>More information about cookies</p>
          <p>You can find out more about cookies, including how to see what cookies have been set and how to manage and delete them by visiting <span className="underline cursor-pointer" onClick={() => { openUrl('www.aboutcookies.org') }}>www.aboutcookies.org</span> or <span className="underline cursor-pointer" onClick={() => { openUrl('www.allaboutcookies.org') }}>www.allaboutcookies.org.</span></p>
          <strong>(8) Change of purpose</strong>
          <p>We will only use your personal data for the purposes for which we collected it, unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose. If you wish to get an explanation as to how the processing for the new purpose is compatible with the original purpose, please contact us using our Contact Details. If we need to use your personal data for an unrelated purpose, we will notify you and we will explain the legal basis which allows us to do so. Please note that we may process your personal data without your knowledge or consent, in compliance with the above rules, where this is required or permitted by law.</p>
          <strong>(9) Disclosures of your personal data</strong>
          <p>We may share your personal data with the parties set out below for the purposes set out in the section ‘Purposes for which we will use your personal data’ above.</p>
          <p>– External Third Parties as set out in the Glossary.
<br />    &#183; Affiliates – our parent company and any subsidiaries, joint venture partners or other companies that we control or that are under common control with us.
            <br />– Third parties to whom we may choose to sell, transfer or merge parts of our business or our assets.</p>
          <p>Alternatively, we may seek to acquire other businesses or merge with them. If a change happens to our business, then the new owners may use your personal data in the same way as set out in this privacy notice.</p>
          <p>We require all third parties to respect the security of your personal data and to treat it in accordance with the law. We do not allow our third-party service providers to use your personal data for their own purposes and only permit them to process your personal data for specified purposes and in accordance with our instructions.</p>
          <span className="underline">California resident – “Do not sell or share”</span>
          <p>The above sharing/transfers to third parties may constitute “sale” of your personal information under California law. 
<br /><strong>If they do constitute a “sale”:</strong> we will include the following "California resident - Do not sell or share my personal data" link in the footer of every page on our website. As a California resident you can halt these transactions at any time by clicking that link. Third parties will not further sell the personal information obtained from us unless you have received explicit notice and an opportunity to opt out.
            <br /> <strong>If we do not “sell” your personal information:</strong> You will not find a "Do not sell or share my personal data" link in the footer of our website.</p>
          <strong>(10) International transfers</strong>
          <p>We may share your personal data with External Third Parties who are or become based outside the UK, so their processing of your personal data will involve a transfer of your personal data outside the UK.
<br />Whenever we transfer your personal data out of the UK, we ensure a similar degree of protection is afforded to it by ensuring at least one of the following safeguards is implemented: 
<br />– We will only transfer your personal data to countries that have been deemed to provide an adequate level of protection for personal data. 
<br />– Where we use certain service providers, we may use specific contracts approved for use in the UK which give personal data the same protection it has in the UK. 
            <br />If your personal data is transferred outside of the UK we will ensure that it is afforded an essentially equivalent level of protection as would be afforded to it within the UK.</p>
          <strong>(11) Data security</strong>
          <p>We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed. In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know. They will only process your personal data on our instructions and they are subject to a duty of confidentiality. We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so.
<br />However, despite our safeguards and efforts to secure your information, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security, and improperly collect, access, steal, or modify your information. 
<br />Accordingly, although we will do our best to protect your personal information, transmission of personal information to and from our Website is at your own risk.</p>
          <strong>(12) Data retention</strong>
          <p><strong>How long will you use my personal data for?</strong>
          <br />We will only retain your personal data for as long as reasonably necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, regulatory, tax, accounting or reporting requirements. We may retain your personal data for a longer period in the event of a complaint or if we reasonably believe there is a prospect of litigation in respect of our relationship with you. To determine the appropriate retention period for personal data, we consider the amount, nature and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal, regulatory, tax, accounting or other requirements. By law we have to keep basic information about our customers (including Contact, Identity, Financial and Transaction Data) for six years after they cease being customers for tax purposes. In some circumstances you can ask us to delete your data: see your legal rights below for further information. In some circumstances we will anonymise your personal data (so that it can no longer be associated with you) for research or statistical purposes, in which case we may use this information indefinitely without further notice to you. </p>
          <strong>(13) Your legal rights</strong>
          <p>Under certain circumstances, you have rights under data protection laws in relation to your personal data. Please see the Glossary to find out more about these rights: 
<br />– Request access to your personal data.
<br />– Request correction of your personal data.
<br />– Request erasure of your personal data.
<br />– Object to processing of your personal data.
<br />– Request restriction of processing your personal data.
<br />– Request transfer of your personal data.
<br />– Right to withdraw consent.
            <br />If you wish to exercise any of the rights set out above, please contact us.</p>
          <p><strong>No fee usually required.</strong> You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we could refuse to comply with your request in these circumstances.
<br /><strong>What we may need from you.</strong> We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.
<br /><strong>Time limit to respond.</strong> We try to respond to all legitimate requests within one month. Occasionally it could take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated. </p>
          <strong>
            (14) Privacy Notice for United States Consumers
            </strong>
            <p>This section of our Privacy Notice applies to consumers who reside in some US States and supplements the information above, as well as the State-Specific information below. 
<br />Privacy laws in certain US States may give consumers rights with respect to their personal information, which could include:
<br />&#183;The right to access the personal information we process about you;
<br />&#183;The right to request the deletion of your personal information;
<br />&#183;The right to request the correction of your personal information;
<br />&#183;The right to opt out of targeted advertising and profiling;
<br />&#183;The right to opt-out of the sale or sharing of your personal information.
<br />You can opt-out of the sale or sharing of your personal information by clicking the ‘Do Not Sell or Share my Personal Information’ link in the footer of the website. 
<br />In addition, residents of some States also have the right to lodge a complaint with its designated Supervisory Authority. Please refer to guidance provided by your relevant Supervisory Authority for further information.
<br />We take the privacy of all of our customers seriously, regardless of location; where we do not fall into scope for a particular State’s privacy law, we still protect customer data in line with this policy.</p>
          <strong>(15) Your California privacy rights</strong>
          <p>The California Consumer Privacy Act 2018 (“CCPA”) and California Privacy Rights Act 2020 (“CPRA”) provide certain rights to residents of California and supplements the above. The CCPA and CPRA are collectively referred to as “CCPA” below.
            <br />If you are a resident of California, the CCPA may provide you with additional rights regarding the use of your personal information. These rights include:</p>
          <p><strong>Right to Know:</strong> At or before the time of collection, you have a right to receive notice of our practices, including the categories of personal data and sensitive personal data to be collected, the purposes for which it is collected and used, whether such personal data is “sold or shared” and for how long personal data is retained. These details are set out in this Privacy Notice.
<br /><strong>Right to Access:</strong> You have the right to request access to the personal data we may hold on you for the past twelve (12) months. 
<br /><strong>Right to Correct:</strong> You have the right to correct inaccurate personal data we hold about you.
<br /><strong>Right to Opt-Out of Sale of Personal Data:</strong> For individuals sixteen (16) years or older, you have the right to opt-out of sale of personal data we may hold on you. 
<br /><strong>Right to Deletion:</strong> You also have the right to ask us to delete personal data we may hold on you or restrict how it is used. There may be exceptions to the right to deletion which, if applicable, we will set out for you in response to your request.
<br /><strong>Right to Limit Use and Disclosure of Sensitive Personal Data:</strong> Where applicable, you have the right to limit our use of sensitive personal data for any purposes other than to provide the services you request or as otherwise permitted by law
<br /><strong>Right to Non-Discrimination:</strong> We will not discriminate against you for exercising any of your CCPA rights.
<br />If you want to exercise any applicable rights, please contact the data privacy manager using the Contact Details in the Legal Information section. Please specify that you are making a request under the CCPA. We may need to request specific information from you to help us confirm your identity and that you are a resident of California before complying. If you provide us with proof of identity and/or residency  containing information that does not match our records, we may request further proof of identity from you.
<br />We endeavour to respond to a verifiable consumer request within 45 days. You may also designate an authorised agent to make a request on your behalf, subject to proof of identity and authorisation. 
<br />We keep a record of requests that we received from users exercising their CCPA rights. If the user does not verify their identity, their request will be denied. </p>
        <p><strong>California “Shine the Light” Privacy Rights</strong>
<br />Residents of California can ask us to provide a list of the types of personal data we have disclosed to third parties for direct marketing purposes and the identity of those third parties. We do not generally disclose personal data as defined under the California “Shine the Light” law. 
<br />To the extent that we share email addresses with third parties in connection with online marketing that could be covered, you may opt-out by contacting the data privacy manager using the Contact Details in the Legal Information section by email with “Shine the Light” in the subject line.</p>
          <strong>(16) Glossary</strong>
          <p><strong>LAWFUL BASIS</strong>
<br /><strong>Legal Information</strong> means the information about our business supplied to you in writing, including at the start of this document (as applicable). 
<br /><strong>Legitimate Interest</strong> means the interest of our business in conducting and managing our business to enable us to give you the best products and services and the best and most secure experience. We make sure we consider and balance any potential impact on you (both positive and negative) and your rights before we process your personal data for our legitimate interests. We do not use your personal data for activities where our interests are overridden by the impact on you (unless we have your consent or are otherwise required or permitted to by law). You can obtain further information about how we assess our legitimate interests against any potential impact on you in respect of specific activities by contacting us.
<br /><strong>Performance of contrac</strong>t means processing your data where it is necessary for the performance of a contract to which you are a party or to take steps at your request before entering into such a contract. 
<br /><strong>Comply with a legal obligation</strong> means processing your personal data where it is necessary for compliance with a legal obligation that we are subject to.
<br /><strong>Consent</strong> means that you have given us permission to process personal information for a given purpose. You have the right to withdraw this consent at any time. You can manage your preferences within your account or by using the Contact Details in the Legal Information.</p>
       <p><strong>THIRD PARTIES</strong>
<br /><strong>External Third Parties</strong>
<br />– Service providers based in the United Kingdom who provide consultancy services, IT services, management services, marketing services, business administration and support services, and associated services.
<br />– Professional advisers including lawyers, bankers, accountants, and insurers based in the United Kingdom who provide consultancy, banking, legal, insurance and accounting services.
<br />– HM Revenue & Customs, regulators and other authorities acting as processors or joint controllers based in the United Kingdom who require reporting of processing activities in certain circumstances.
<br />– Any other third party with whom we are required by law or regulation to share your personal data (such as in connection with an investigation of fraud or other legal enquiry) or in connection with other legal proceedings (including where we believe that your actions violate applicable laws or any agreement with us).
<br />– Service providers based outside the United Kingdom and EEA to whom we subcontract, outsource or use to provide services for the purposes set out in this notice</p>
          <strong>YOUR LEGAL RIGHTS</strong>
          <p>You have the right to:
<br /><strong>Request access</strong> to your personal data (commonly known as a "data subject access request"). This enables you to receive a copy of the personal data we hold about you and to check that we are lawfully processing it.
<br /><strong>Request correction</strong> of the personal data that we hold about you. This enables you to have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the accuracy of the new data you provide to us.
<br /><strong>Request erasure</strong> of your personal data. This enables you to ask us to delete or remove personal data where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal data where you have successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully or where we are required to erase your personal data to comply with local law. Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request. 
<br /><strong>Object to processing</strong> of your personal data where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation which makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. You also have the right to object where we are processing your personal data for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your rights and freedoms.
<br /><strong>Request restriction of processing</strong> of your personal data. This enables you to ask us to suspend the processing of your personal data in the following scenarios: 
<br />– If you want us to establish the data's accuracy.
<br />– Where our use of the data is unlawful but you do not want us to erase it.
<br />– Where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims. 
<br />– You have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it. 
<br /><strong>Request the transfer</strong> of your personal data to you or to a third party. We will provide to you, or a third party you have chosen, your personal data in a structured, commonly used, machine-readable format. Note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform a contract with you. 
<br /><strong>Withdraw consent at any time</strong> where we are relying on consent to process your personal data. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain products or services to you. We will advise you if this is the case at the time you withdraw your consent.</p>
          <p>Please note that these rights do not apply in all situations and may be subject to the provisions of relevant data protection laws. We may not be able to comply with any request from you in connection with the rights set out above. (For example, even if you request that we delete your personal data, we may be required by law to retain some personal data in order that we comply with our legal and regulatory obligations).</p>
          <strong>Businesses and consumers</strong>
          <p>The law relating to digital marketing draws a distinction between ‘corporate subscribers’ and ‘individual subscribers.’
<br />‘Corporate subscribers’ are corporate bodies with separate legal status. This includes companies, limited liability partnerships, Scottish partnerships, and some government bodies.
            <br />‘Individual subscribers’ are individual customers, non-limited liability partnerships, English, Welsh and Northern Irish partnerships and other unincorporated bodies of individuals (eg residents’ association).</p>
          <p>Because these terms are not in everyday use, in this Privacy Notice we refer to ‘Corporate subscribers’ as businesses and ‘Individual subscribers’ as consumers.</p>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
